import { CacheProvider } from '@emotion/core'
import { AppProps } from 'next/app'
import { cache } from 'emotion'
import { DefaultSeo } from 'next-seo'

import { globalStyles } from '../clients/styles/style'
import Head from 'next/head'
import { useEffect, useState } from 'react'
import { initFirebaseModules } from '../clients/firebase/helpers'
import { useRouter } from 'next/router'
import LoadingBar from 'react-top-loading-bar'
import { colors } from '@/clients/styles/theme'

const App = (props: AppProps) => {
  const { Component, pageProps } = props
  const [progress, setProgress] = useState(0);
  const router = useRouter();

  useEffect(() => {
    initFirebaseModules()

    // START VALUE - WHEN LOADING WILL START
    router.events.on("routeChangeStart", () => {
      setProgress(40);
    });

    // COMPLETE VALUE - WHEN LOADING IS FINISHED
    router.events.on("routeChangeComplete", () => {
      setProgress(100);
    });

  }, [])
  return (
    <CacheProvider value={cache}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#5bbad5" />
      </Head>
      {globalStyles}
      <DefaultSeo
        defaultTitle="Gamee(ゲーミー) | 息ぴったりなゲーム友達が見つかるサイト"
        titleTemplate="%s | Gamee(ゲーミー)"
        description="Apex募集が自動でできるサイトです。ゲーム友達を募集する時に募集掲示板として使ってみてください。またオンラインゲームの自己紹介カードもつくれるのでフレンド募集に役立ててください。gemee(ゲーミー)で息ぴったりなゲーム友だちを見つけよう。"
        twitter={{
          site: '@gamee_games',
          cardType: 'summary_large_image',
        }}
        openGraph={{
          type: 'website',
          locale: 'ja_JP',
          url: 'https://gamee.games',
          site_name: 'Gamee(ゲーミー)',
          title: 'gamee | 息ぴったりなゲーム友だちを見つけよう',
          description: 'ゲーム友だちの検索はこちら！',
          images: [
            {
              url: 'https://gamee.games/ogp.png',
              alt: 'Gamee(ゲーミー) | 息ぴったりなゲーム友達が見つかるサイト',
              width: 600,
              height: 314,
            },
          ],
        }}
      />
      <LoadingBar
        color={colors.border.purple}
        progress={progress}
        waitingTime={400}
        onLoaderFinished={() => {
          setProgress(0);
        }}
      />
      <Component {...pageProps} />
    </CacheProvider>
  )
}

export default App
